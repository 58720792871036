<template>
  <div class='wameed-dashboard-page-content chat-page'>

    <page-header
      :title="$t('chats.title')"
    />
    <div>

      <chat-window
        height='calc(100vh - 180px)'
        :current-user-id='currentUserId'

        :load-first-room='true'
        :room-actions='[]'

        :menu-actions="[
              {
                name: 'addUser',
                title: $t('chats.actions.add_user'),
              },
              {
                name: 'closeChat',
                title: $t('chats.actions.close_chat'),
              },
                ]"
        :accepted-files="'image/png, image/jpeg'"

        :room-id='openedRoomId'
        :rooms='getRooms'
        :rooms-loaded='roomsLoaded'
        :loading-rooms='loadingRooms'
        :messages='getRoomMessages'
        :messages-loaded='messagesLoaded'
        :room-info-enabled='false'
        :show-add-room='false'
        :emojis-suggestion-enabled='false'
        :show-reaction-emojis='false'
        :show-emojis='false'
        :message-selection-actions='[]'
        :message-actions='[]'
        :show-new-messages-divider='false'
        :show-audio='false'

        @fetch-messages='onFetchMessages'
        @fetch-more-rooms='fetchMoreRooms'
        @menu-action-handler='menuActionHandler'
        @send-message='sendMessage'
        :text-messages="{
            TYPE_MESSAGE: $t('chats.enter_message'),
            SEARCH: $t('chats.search'),
          }"
      >

        <div slot='send-icon' class='rotate-icon-90'>
          <chat-send-icon style='width:40px;height:40px' />
        </div>

        <div slot='messages-empty'>
          <wameed-no-data
            style='height:calc(100vh - 400px)'
            :title="$t('chats.no_messages.title')"
            :sub-title="$t('chats.no_messages.sub-title')"
          />
        </div>

        <div slot='rooms-empty'>
          <div>{{ $t('chats.no_rooms.sub-title') }}</div>
        </div>


        <template #room-list-item='{ room }'>
          <div class='vac-room-container'>
            <div class='vac-avatar' :style='`background-image: url(${room.roomName});`'></div>
            <div class='vac-name-container vac-text-ellipsis ml-3'>
              <div class='vac-title-container'>
                <div class='vac-room-name vac-text-ellipsis'>
                  {{ room.roomName }}
                  <span v-if='room.closedById' class='text-danger'>({{ $t('common.closed') }})</span>
                </div>
                <div class='vac-text-date'>{{ room.lastMessage.timestamp }}</div>
              </div>
              <div class='vac-text-last'>
                <div class='vac-format-message-wrapper vac-text-ellipsis'>
                  <div class='vac-text-ellipsis'>
                    <div class='vac-format-container'>
                      <span class='vac-text-ellipsis'>
                      <span>{{ room.lastMessage.content }}</span>
                    </span>
                    </div>
                  </div>
                </div>
                <div class='vac-room-options-container'>
                  <div class='vac-badge-counter vac-room-badge' v-if='room.unreadCount'> {{ room.unreadCount }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>


        <template #message='{ message }'>

          <template v-if="message.type!='add' && message.type!='close'" :id='message._id'>

            <div v-if='message.senderId  !== currentUserId'
                 class='vac-avatar vac-avatar-current'
                 :style='`background-image: url(${message.avatar});`'>
            </div>
            <div class='vac-message-container '
                 :class="message.senderId  === currentUserId?'':'vac-messsage-container-offset'"
            >
              <div class='vac-message-card '
                   :class="message.senderId  === currentUserId?'':'vac-message-current'"
              >

                <div class='vac-message-files-container' v-for='file in message.files'>
                  <div>
                    <div class='vac-message-file-container'>
                      <div class='vac-message-image-container'>
                        <a class='vac-message-image' :href='file.url' target='_blank'
                           :style='`background-image: url(${file.url}); max-height: 232px;display:block`'>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class='vac-format-message-wrapper'>
                    <div class=''></div>
                  </div>
                </div>

                <div class='vac-text-username'
                     v-if='message.senderId  !== currentUserId'>
                    <span>
                      {{ message.username }}
                  </span>
                </div>
                <div class='vac-format-message-wrapper'>
                  <div class=''>
                    <div class='vac-format-container'><span class=''><span>{{ message.content }}</span></span>
                    </div>
                  </div>
                </div>
                <div class='vac-text-timestamp'><span>{{ message.timestamp }}</span></div>
                <div class='vac-message-actions-wrapper'>
                  <div class='vac-options-container' style='display: initial; width: 45px;'><span></span></div>
                </div>
              </div>

            </div>


          </template>
          <template v-else-if="message.type=='add'">
            <div class='chat-action-container'>
              <div class='chat-action'>

                {{ message.content.replace('+', ' ' + $t('chats.actions.added') + ' ') }}
              </div>
            </div>
          </template>
          <template v-else-if="message.type=='close'">
            <div class='chat-action-container'>
              <div class='chat-action'>

                {{ message.content + ' ' + $t('chats.actions.closed') }}
              </div>
            </div>
          </template>


        </template>

        <template #room-header-info='{ room }'>
          <div class='vac-text-ellipsis'>
            <div class='vac-room-name vac-text-ellipsis'> {{ room.roomName }}</div>
            <div class='vac-room-info vac-text-ellipsis'></div>
          </div>
        </template>

        <div slot='no-room-selected'>
          <wameed-no-data
            style='height:calc(100vh - 180px)'
            :title="$t('chats.no_messages.title')"
            :sub-title="$t('chats.no_messages.sub-title')"
          />
        </div>
      </chat-window>


    </div>

    <admin-list-modal
      :visible='showAdminList'
      @close='showAdminList = false'
      @addClick='addUserToRoom'
    />

    <warning-modal
      variant='main'
      iconName='o-toggle-icon'
      :visible='showCloseModal'
      @close='showCloseModal = false'
      @submitAction='closeRoom'
      :title="$t('chats.actions.close_chat')"
      :subTitle="$t('chats.actions.close_subtitle')"
      :btnTitle="$t('chats.actions.close_btn')"
    />
  </div>
</template>

<script>

import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';

import { WameedNoData } from 'wameed-ui/dist/wameed-ui.esm';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import '@/assets/scss/_chat.scss';


import { mapGetters } from 'vuex';
import { MessageTypeEnum } from '@/enums/messageType.enum';
import AdminListModal from '@/views/chats/components/adminListModal';
import WarningModal from '@/components/WarningModal';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default {
  components: {
    WarningModal,
    AdminListModal,
    ChatWindow,
    WameedNoData,
    PageHeader
  }, data() {
    return {
      showAdminList: false,
      showCloseModal: false,
      roomsIds: [],
      openedRoomId: '',
      currentUserId: '1',
      rooms: [],
      roomsLoaded: false,
      messagesLoaded: false,
      loadingRooms: false,
      messages: []
    };
  },
  //watch the getActiveRoom to get the room id
  watch: {
    getActiveRoom: function(val) {
      this.onFetchMessages(null, true);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'profile/getBasic',
      getRooms: 'chat/getRooms',
      getRoomMessages: 'chat/getRoomMessages',
      getActiveRoom: 'chat/getActiveRoom'
    })
  },
  methods: {

    async sendMessage(message) {

      let object = {
        roomId: message.roomId,
        message: {
          content: message.content,
          type: 'text'
        },
        user: {
          id: this.currentUser.id.toString(),
          name: this.currentUser.name,
          avatar: this.currentUser.image,
          type: 1//userTypes.admin,
        }
      };

      if ('files' in message && message.files != null) {
        const formData = new FormData();
        for (let i = 0; i < message.files.length; i++) {
          const file = new File(
            [message.files[i].blob],
            `${message.files[i].name}.${message.files[i].extension}`,
            {
              type: message.files[i].type,
              lastModified: new Date().getTime()
            }
          );
          formData.append('file', file);
          await this.$store.dispatch('utilities/uploadFiles', formData).then(async (url) => {

            object['message'] = {
              content: url,
              type: 'image'
            };

            await this.$store.dispatch('chat/sendMessage', object);
          });
        }

      } else {

        await this.$store.dispatch('chat/sendMessage', object);
      }


    },

    menuActionHandler({ roomId, action }) {
      this.openedRoomId = roomId;
      switch (action.name) {
        case 'addUser':
          this.showAdminList = true;
          break;
        case 'closeChat':
          this.showCloseModal = true;
          break;
      }
    },
    async addUserToRoom(user) {

      let object = {
        roomId: this.openedRoomId,
        sender_id: this.currentUser.id.toString(),
        message: {
          content: this.currentUser.name + '+' + user.name,
          type: MessageTypeEnum.add
        },
        user: {
          id: user.id.toString(),
          name: user.name,
          avatar: user.image,
          type: 1//userTypes.admin,
        }
      };
      await this.$store.dispatch('chat/addUserToRoom', object);
      this.showAdminList = false;

    },

    async closeRoom() {

      let object = {
        roomId: this.openedRoomId,
        sender_id: this.currentUser.id.toString(),
        message: {
          content: this.currentUser.name,
          type: MessageTypeEnum.close
        }

      };
      await this.$store.dispatch('chat/closeRoom', object).then((val) => {

        this.hideShowRoomElements(val);

      });
      this.showCloseModal = false;

    },


    fetchMoreRooms() {
    },
    getChat() {
      this.roomsLoaded = false;

      this.$store.dispatch('chat/getRooms').then(() => {
        this.roomsLoaded = true;
      });

    },


    onFetchMessages(room, getActiveRoom = false) {
      //disable the footer if the room is closed
      if (getActiveRoom)
        room = { room: this.getActiveRoom };


      this.hideShowRoomElements(room.room.closedById);
      if (this.openedRoomId !== room.room.roomId) {
        this.openedRoomId = room.room.roomId;
        this.messagesLoaded = false;

        this.$store.dispatch('chat/getRoomMessages', room.room.roomId).then(() => {
          this.messagesLoaded = true;
        });
      }


    },

    hideShowRoomElements(hide) {
      let footer = document.getElementById('room-footer');
      let textarea = document.getElementById('roomTextarea');
      let menu = document.getElementsByClassName('vac-room-options')[0];
      try {

        if (hide) {
          textarea.setAttribute('disabled', 'disabled');
          menu.setAttribute('style', 'display:none');
          footer.classList.add('chat-message-closed');
        } else {
          textarea.removeAttribute('disabled');
          menu.removeAttribute('style');
          footer.classList.remove('chat-message-closed');
        }

      } catch (e) {

      }
    }
  },

  async mounted() {
    let auth = getAuth();
    onAuthStateChanged(auth, async (user) => {

      if (!user) {
        await this.$store.dispatch('auth/firebaseLogin');
      } else {
        await this.getChat();
        if (this.currentUser)
          this.currentUserId = this.currentUser.id.toString();
      }
    });

  }
};


</script>

<style>
.chat-message-closed {
  pointer-events: none !important;
}

.chat-message-closed .vac-svg-button {
  filter: grayscale(1);
  opacity: 0.2;
}

.vac-message-box:has(> .chat-action-container) {
  max-width: unset !important;
  margin: unset !important;
}

.chat-action-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.chat-action {
  display: inline-block;
  background: rgb(0, 119, 238, 10%);
  color: black;
  padding: 4px 14px;
  text-align: center;
  border-radius: 8px;
  margin: 12px 0;
}

.vac-menu-options {
  right: 10px;
  left: unset;
}

.vac-room-header .vac-room-name {
  margin-left: 14px;
}
</style>
